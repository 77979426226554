import React, { Fragment } from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Newsletter from "../../components/newsletter/newsletter";
import Layout from "../../components/layout/layout";
import formatTitle from "../../components/common/formatTitle";
import randomKey from "../../helpers/randomKey";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  mT: {
    marginTop: theme.spacing(3),
  },
  mTPlus: {
    marginTop: theme.spacing(6),
  },
  mB: {
    marginBottom: theme.spacing(3),
  },
  mBPlus: {
    marginBottom: theme.spacing(6),
  },
  em: {
    color: theme.palette.primary.light,
  },
  morePadded: {
    padding: `0 ${theme.spacing(14)}px`,
  },
  link: {
    color: "#000",
  },
  payAttention: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.medium,
    padding: theme.spacing(2),
    lineHeight: "30px",
  },
}));

const Privacy = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const personalDatasIdx =
    data.allPrivacyJson.nodes[0][langKey].length === 6 ? 0 : 1;
  const cookieIdx = data.allPrivacyJson.nodes[0][langKey].length === 2 ? 0 : 1;

  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item container direction="column" alignItems="stretch">
        <Grid
          item
          className={clsx({
            [classes.paddingRegular]: matches,
            [classes.paddingRegularMobile]: !matches,
          })}
        >
          <div className={clsx({ [classes.morePadded]: matches })}>
            <Typography variant="body2" color="textPrimary">
              Mis à jour le 22 septembre 2022
            </Typography>
            <Typography variant="h4">Politique de confidentialité</Typography>
            <div
              style={{
                marginBottom: theme.spacing(5),
                marginTop: theme.spacing(5),
                border: "solid 1px rgba(206,214,233, .3)",
              }}
            />
            <Typography
              variant="h5"
              className={clsx(classes.mT, classes.mBPlus)}
            >
              Données personnelles collectées via le site
            </Typography>
            {data.allPrivacyJson.nodes[personalDatasIdx][langKey].map(
              (d, index) => (
                <Fragment key={`all-privacy-json-${randomKey()}`}>
                  <Typography
                    variant="h6"
                    className={clsx(classes.mT, classes.mB, classes.em)}
                    key={index}
                  >
                    {d.title}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {formatTitle(d.content)}
                  </Typography>
                </Fragment>
              )
            )}
            <div
              style={{
                marginBottom: theme.spacing(5),
                marginTop: theme.spacing(5),
                border: "solid 1px rgba(206,214,233, .3)",
              }}
            />
            <Typography
              variant="h5"
              className={clsx(classes.mTPlus, classes.mBPlus)}
            >
              Politique sur les cookies
            </Typography>
            {data.allPrivacyJson.nodes[cookieIdx][langKey].map((d, index) => (
              <Fragment key={`all-privacy-json-1-${randomKey()}`}>
                <Typography
                  variant="h6"
                  className={clsx(classes.mT, classes.mB, classes.em)}
                  key={index}
                >
                  {d.title}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {formatTitle(d.content)}
                </Typography>
              </Fragment>
            ))}
            <div
              style={{
                marginBottom: theme.spacing(5),
                marginTop: theme.spacing(5),
                border: "solid 1px rgba(206,214,233, .3)",
              }}
            />
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.payAttention}
            >
              {"Plus d’information sur les cookies sur le site de la CNIL :"}
              <br />
              <a
                href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/"
                target="__blank"
                className={classes.link}
              >
                http://www.cnil.fr/vos-droits/vos-traces/les-cookies/
              </a>
            </Typography>
          </div>
          <Newsletter />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Privacy;

export const query = graphql`
  query PrivacyQueryFr {
    allPrivacyJson {
      nodes {
        fr {
          title
          content
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "gatsby-material-ui-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SimpleNewsletterForm from "../common/SimpleNewsletterForm";
import BlueMaskImg from "./blue_mask.png";
import GreemMaskImg from "./green_mask.png";
import BlueMaskImg2 from "./blue_mask2.png";
import GreemMaskImg2 from "./green_mask2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: `url(${BlueMaskImg}), url(${GreemMaskImg}), url(${GreemMaskImg2}), url(${BlueMaskImg2})`,
    backgroundRepeat: "no-repeat, no-repeat, no-repeat",
    backgroundPosition: "50% 100%, 100% 100%, 0 100%, 0% 0%",
    backgroundSize: "auto 90%, auto 70%, auto 50%, auto 70%",
    borderRadius: "20px",
  },
  rootRegular: {
    padding: theme.spacing(4),
  },
  rootMobile: {
    padding: theme.spacing(1),
  },
  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
    color: "#000",
  },
  title: {
    width: "60%",
  },
  titleMobile: {
    width: "80%",
  },
  cta: {
    width: "30%",
    textAlign: "right",
  },
  ctaMobile: {
    width: "100%",
    textAlign: "center",
  },
  write: {
    backgroundColor: "#fff",
    color: "#000",
  },
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Fade = styled.div`
  visibility: ${(props) => (props.out ? "hidden" : "visible")};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)}
    ${(props) => (props.out ? 1.3 : 0.5)}s linear;
  transition: visibility 1s linear;
`;

const Newsletters = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [go, setGo] = useState(false);
  const letsGo = () => {
    setGo(true);
  };
  const matchesMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justify="center"
      className={props.className ? props.className : classes.root1}
    >
      <Grid
        item
        container
        direction={matchesMobile ? "column" : "row"}
        alignItems="center"
        justify="center"
        className={clsx(classes.root, {
          [classes.rootMobile]: matchesMobile,
          [classes.rootRegular]: !matchesMobile,
        })}
        spacing={matchesMobile ? 2 : 0}
      >
        <Grid
          item
          className={clsx({
            [classes.title]: !matchesMobile,
            [classes.titleMobile]: matchesMobile,
          })}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: 600,
              color: "#FFF",
              fontSize: matchesMobile ? "1.1rem" : "2.5rem",
              textAlign: matchesMobile ? "center" : "left",
            }}
          >
            {props.children ? (
              props.children
            ) : (
              <span>
                {props.langKey === "en"
                  ? "Subscribe to our"
                  : "S’inscrire à notre"}
                {!matchesMobile && <br />} newsletter
                {props.langKey === "fr" && " "}!
              </span>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          className={clsx({
            [classes.cta]: !matchesMobile,
            [classes.ctaMobile]: matchesMobile,
          })}
        >
          {!go ? (
            <Button
              variant="contained"
              color="secondary"
              size={matchesMobile ? "medium" : "large"}
              onClick={letsGo}
            >
              {props.langKey === "fr"
                ? props.btnLabel
                  ? props.btnLabel
                  : "C’est parti !"
                : props.btnLabel
                ? props.btnLabel
                : "Let's Go!"}
            </Button>
          ) : (
            <SimpleNewsletterForm
              large={false}
              langKey={props.langKey}
              xsInput={matchesMobile ? 8 : 10}
              xsBtn={matchesMobile ? 2 : 2}
              justify={matchesMobile ? "center" : "space-between"}
              whiteButton
              successMessage={
                props.langKey === "fr"
                  ? "Inscription validée"
                  : "Subscription registered"
              }
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Newsletters.propTypes = {
  langKey: PropTypes.string,
  children: PropTypes.object,
  btnLabel: PropTypes.string,
  className: PropTypes.string,
};

export default Newsletters;

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import randomKey from "../../helpers/randomKey";
const useStyles = makeStyles((theme) => ({
  enLight: {
    color: theme.palette.primary.light,
  },
}));

const formatTitle = (title, className = null) => {
  const classes = useStyles();
  return title.indexOf("!!") === -1
    ? title
    : title.split(" ").reduce(
        (memo, word) =>
          word.indexOf("!!") !== -1
            ? memo.concat(
                <span
                  className={className || classes.enLight}
                  key={`spanFormatTitle-${randomKey()}`}
                >
                  {word.replace("!!", " ")}
                </span>
              )
            : memo.concat([`${word} `]),
        []
      );
};

export default formatTitle;
